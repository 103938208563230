import { useEffect, useState } from 'react'

function useAdBlockDetector() {
  const [isBlocked, setIsBlocked] = useState(false)
  useEffect(() => {
    const { display } = getComputedStyle(document.getElementById('detect-ad-blocker'))
    if (display === 'none') setIsBlocked(true)
  }, [])
  return {
    isBlocked
  }
}
export default useAdBlockDetector
